import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import MainPage from './MainPage';
import ContactPage from './ContactPage';
import ComingSoon from './ComingSoon';

export default function SwitchPage() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
            </Routes>
        </BrowserRouter>
    );
}