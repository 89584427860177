import React from 'react';


export default function ContactPage() {
    return (
        <div style={{
            background: 'black',
            color: 'white',
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <h1 style={{fontSize: '16px'}}>contact@chaindepo.org</h1>
        </div>
    );
}