import React, { useRef, useState } from 'react';
import styles from './MainPage.module.css';
import { NavLink } from "react-router-dom";
import video from './videos/background.mp4'


function Navigator() {
    return (
        <nav className={styles.NavBar}>
            <NavLink to='contact'>Contact</NavLink>
            <NavLink to='coming-soon'>Exchange Demo</NavLink>
        </nav>
    );
}

export default function MainPage() {
    const videoRef = useRef();
    const [opacity, setOpacity] = useState(0);

    return (
        <div className={styles.MainPage}>
            <div onClick={_ => videoRef.current.play()} className={styles.BackgronudVideo}>
                <video className={styles.BackgroundVideo__PLayer} style={{ opacity }} onCanPlayThrough={_ => setOpacity(1)} ref={videoRef} autoPlay loop>
                    <source src={video} type="video/mp4"/>
                </video>
            </div>
            <div className={styles.MainPage__Container}>
                <Navigator />
                <section className={styles.PageText} onClick={_ => videoRef.current.play()}>
                    <h1>Building Future Fintech: <br />
                    Blockchain based <br />
                    Securities Depository</h1>
                    <p>We are building blockchain based international central securities depository providing new ecosystem for the world trade markets. Our goal is integrating crypto currencies into the future of economy and building clear and secure finance with global access.</p>
                </section>
                <section className={styles.BottomText}>
                    Chain Depository Proposal | 2022
                </section>
            </div>
        </div>
    )
}